import axios from '@/config/axios';
import { klantIndexById, voldoetAanFilter, voldoetAanAfstand } from '@/utils/filterFunctions';

// TODO: Onderscheid filterRechts en getoondRechts anders oplossen of duidelijjker maken.
// TODO: Hotlist oplossing zoals getoondRechts? Zodat ongetoonde klanten niet met muiswiel langskomen.

export default {
    state: {
        currentFilterRechtsSettings: null,
        filterRechts: [],
        getoondRechts: [],
        currentKlantRechts: -1,
        afstanden: null,
        voorstellenKlantRechts: [],
        hotlist: [],
        statussenTonenRechts: ['4.0 Beschikbaar', '4.1 Prioriteit'],
        passiefTonenRechts: false,
    },
    mutations: {
        SET_FILTER_RECHTS_SETTINGS(state, filter) {
            state.currentFilterRechtsSettings = filter;
        },
        SET_FILTER_RECHTS(state, klanten) {
            state.filterRechts = klanten;
        },
        SET_AFSTANDEN(state, afstanden) {
            state.afstanden = afstanden;
        },
        CLEAR_FILTER_RECHTS(state) {
            state.currentFilterRechtsSettings = null;
            state.filterRechts = [];
            state.getoondRechts = [];
            state.currentKlantRechts = -1;
            state.afstanden = null;
            state.hotlist = [];
            state.statussenTonenRechts = ['4.0 Beschikbaar', '4.1 Prioriteit'];
            state.passiefTonenRechts = false;
        },
        SET_CURRENT_KLANT_RECHTS(state, index) {
            state.currentKlantRechts = index;
        },
        SET_VOORSTELLEN_KLANT_RECHTS(state, voorstellen) {
            state.voorstellenKlantRechts = voorstellen;
        },
        CLEAR_VOORSTELLEN_KLANT_RECHTS(state) {
            state.voorstellenKlantRechts = [];
        },
        ADD_TO_HOTLIST(state, id) {
            state.hotlist.push(id);
        },
        REMOVE_FROM_HOTLIST(state, id) {
            const newList = state.hotlist.filter((e) => e !== id);
            state.hotlist = newList;
        },
        SET_STATUSSEN_RECHTS(state, list) {
            state.statussenTonenRechts = list;
        },
        SET_PASSIEF_FILTER_RECHTS(state, tonen) {
            state.passiefTonenRechts = tonen;
        },
        VOLGENDE_KLANT_RECHTS(state) {
            state.currentKlantRechts = state.currentKlantRechts + 1;
        },
        VORIGE_KLANT_RECHTS(state) {
            state.currentKlantRechts = state.currentKlantRechts - 1;
        },
        UPDATE_GETOOND_RECHTS(state, getoond) {
            state.getoondRechts = getoond;
        },
    },
    actions: {
        async maakFilterRechts({ commit, state, dispatch, rootState, rootGetters }, filter) {
            commit('SET_CURRENT_KLANT_RECHTS', -1);
            const reedsVoorgesteld = rootGetters.voorstellenKlantLinks.map((e) => e.ID_Persoon_2?.id || '');
            const blacklistVoorstellen = rootGetters.blacklistLinks.map((e) => e.ID_Persoon_2?.id || '');
            const klanten = rootState.BemiddelingStore.klanten.filter((klant) => {
                return voldoetAanFilter(klant, filter, reedsVoorgesteld, blacklistVoorstellen);
            });
            // Filter afstanden
            const postcodes = klanten.map((e) => (e.Postcode ? e.Postcode.substr(0, 4) : '0000'));
            await dispatch('haalAfstandenVanafLinks', {
                van: filter.postcode,
                naar: postcodes,
            });
            const klantenBinnenAfstand = klanten.filter((klant) => voldoetAanAfstand(klant, filter, state.afstanden));
            commit('SET_FILTER_RECHTS', klantenBinnenAfstand);
            commit('SET_FILTER_RECHTS_SETTINGS', filter);
            dispatch('updateGetoondRechts');
        },
        async haalAfstandenVanafLinks({ commit }, postcodes) {
            const afstanden = await axios.post('/afstand', {
                van: postcodes.van,
                naar: postcodes.naar,
            });
            commit('SET_AFSTANDEN', afstanden.data);
        },
        kiesKlantRechts({ commit, state }, id) {
            const index = klantIndexById(id, state.getoondRechts);
            commit('SET_CURRENT_KLANT_RECHTS', index);
        },
        sorteerFilterRechts({ commit, dispatch, state }, sortering) {
            const currentId = state.getoondRechts?.[state.currentKlantRechts]?.id || null;

            if (sortering === 'km') {
                const gesorteerd = [...state.getoondRechts].sort((a, b) => {
                    const postcodeA = a.Postcode.substr(0, 4);
                    const postcodeB = b.Postcode.substr(0, 4);
                    const afstandA = state.afstanden?.[postcodeA]?.km || 1000;
                    const afstandB = state.afstanden?.[postcodeB]?.km || 1000;
                    return afstandA - afstandB;
                });
                commit('SET_FILTER_RECHTS', gesorteerd);
            }

            if (sortering === 'tijd') {
                const gesorteerd = [...state.getoondRechts].sort((a, b) => {
                    const postcodeA = a.Postcode.substr(0, 4);
                    const postcodeB = b.Postcode.substr(0, 4);
                    const afstandA = state.afstanden?.[postcodeA]?.minuten || 1000;
                    const afstandB = state.afstanden?.[postcodeB]?.minuten || 1000;
                    return afstandA - afstandB;
                });
                commit('SET_FILTER_RECHTS', gesorteerd);
            }

            if (sortering === 'status') {
                const gesorteerd = [...state.getoondRechts].sort((a, b) =>
                    a.Status_bemiddeling > b.Status_bemiddeling ? 1 : -1
                );
                commit('SET_FILTER_RECHTS', gesorteerd);
            }

            if (sortering === 'naam') {
                const gesorteerd = [...state.getoondRechts].sort((a, b) => (a.Account_Name > b.Account_Name ? 1 : -1));
                commit('SET_FILTER_RECHTS', gesorteerd);
            }

            if (sortering === 'woonplaats') {
                const gesorteerd = [...state.getoondRechts].sort((a, b) => (a.Stad > b.Stad ? 1 : -1));
                commit('SET_FILTER_RECHTS', gesorteerd);
            }

            dispatch('updateGetoondRechts');

            if (state.currentKlantRechts > -1) {
                const newIndex = klantIndexById(currentId, state.getoondRechts);
                commit('SET_CURRENT_KLANT_RECHTS', newIndex);
            }
        },
        initFilterRechts({ commit, rootGetters }) {
            const klantLinks = rootGetters.currentKlantLinks;

            // uitfilteren
            const checkKinderenArray = (arr) => {
                if (arr === null) return null;

                if (arr.includes('Onbelangrijk')) return 'onbelangrijk';
                if (arr.includes('Nee')) return 'nee';
                if (arr.includes('Ja')) return 'ja';
                return null;
            };

            const checkHuisdierenArray = (arr) => {
                if (arr.includes('Allergie') || arr.includes('Nee')) return 'nee';
                if (arr.includes('Ja')) return 'ja';
                if (arr.includes('Bespreekbaar') || arr.includes('Onbelangrijk')) return 'onbelangrijk';
                return null;
            };

            const checkGeloofItem = (item) => {
                if (item === 'Onbelangrijk') return 'onbelangrijk';
                if (item === 'Wel gelovig') return 'ja';
                if (item === 'Niet gelovig') return 'nee';
            };

            const checkRokenArray = (arr) => {
                if (arr.includes('Absoluut niet')) return 'nee';
                if (arr.includes('Onbelangrijk')) return 'onbelangrijk';
                if (arr.includes('Liever niet')) return 'matig';
                return null;
            };

            if (klantLinks && klantLinks.id) {
                const filter = {
                    geslacht: klantLinks?.Geslacht === 'Man' ? 'vrouw' : 'man' || null,
                    leeftijd_van: klantLinks?.Leeftijd_van || null,
                    leeftijd_tot: klantLinks?.Leeftijd_tot || null,
                    niveau: {
                        lbo: klantLinks?.Ontwikkeling_zoeken.includes('LBO') || null,
                        mbo: klantLinks?.Ontwikkeling_zoeken.includes('MBO') || null,
                        hbo: klantLinks?.Ontwikkeling_zoeken.includes('HBO') || null,
                        wo: klantLinks?.Ontwikkeling_zoeken.includes('WO') || null,
                    },
                    postuur: {
                        mager: klantLinks?.Postuur_zoeken.includes('Mager') || null,
                        slank: klantLinks?.Postuur_zoeken.includes('Slank') || null,
                        normaal: klantLinks?.Postuur_zoeken.includes('Normaal') || null,
                        volslank: klantLinks?.Postuur_zoeken.includes('Volslank') || null,
                        zwaar: klantLinks?.Postuur_zoeken.includes('Zwaar / Obese') || null,
                    },
                    huidskleur: {
                        blank: klantLinks?.Huidskleur_zoeken.includes('Blank') || null,
                        getint: klantLinks?.Huidskleur_zoeken.includes('Getint') || null,
                        zwart: klantLinks?.Huidskleur_zoeken.includes('Zwart') || null,
                    },
                    lengte_van: klantLinks?.Lengte_van || null,
                    lengte_tot: klantLinks?.Lengte_tot || null,
                    werkzaam: klantLinks?.Werk_zoeken?.toLowerCase() || null,
                    kinderen: checkKinderenArray(klantLinks?.Kinderen_zoeken),
                    kinderwens: klantLinks?.Kinderwens.toLowerCase() || null,
                    huisdieren: checkHuisdierenArray(klantLinks?.Huisdieren_zoeken) || null,
                    geloof: checkGeloofItem(klantLinks?.Geloof_zoeken) || null,
                    roken: checkRokenArray(klantLinks?.Roken_zoeken) || null,
                    kilometers: klantLinks?.Reisafstand_kilometers || null,
                    minuten: klantLinks?.Reistijd_minuten || null,
                    burgerlijke_staat: null,
                };

                commit('SET_FILTER_RECHTS_SETTINGS', filter);
            }
        },
        async haalVoorstellenKlantRechts({ commit, state }) {
            commit('CLEAR_VOORSTELLEN_KLANT_RECHTS');
            // TODO: Debounce bij snel klikken
            const id = state.getoondRechts[state.currentKlantRechts].id;
            const voorstellen = await axios.get(`/zoho/voorstellen/${id}`);
            const voorstellenGesorteerd = voorstellen.data.sort((a, b) => {
                return new Date(a.Datum_voorstel) > new Date(b.Datum_voorstel) ? -1 : 1;
            });
            commit('SET_VOORSTELLEN_KLANT_RECHTS', voorstellenGesorteerd);
        },
        addToHotlist({ commit }, id) {
            commit('ADD_TO_HOTLIST', id);
        },
        removeFromHotlist({ commit }, id) {
            commit('REMOVE_FROM_HOTLIST', id);
        },
        setStatussenFilterRechts({ commit, dispatch }, list) {
            commit('SET_STATUSSEN_RECHTS', list);
            dispatch('updateGetoondRechts');
        },
        setPassiefFilterRechts({ commit, dispatch }, tonen) {
            commit('SET_PASSIEF_FILTER_RECHTS', tonen);
            dispatch('updateGetoondRechts');
        },
        volgendeKlantRechts({ state, commit }) {
            if (state.currentKlantRechts < state.getoondRechts.length - 1) {
                commit('VOLGENDE_KLANT_RECHTS');
            }
        },
        vorigeKlantRechts({ state, commit }) {
            if (state.currentKlantRechts > 0) {
                commit('VORIGE_KLANT_RECHTS');
            }
        },
        updateGetoondRechts({ state, commit }) {
            const getoond = state.filterRechts
                .filter((e) => state.statussenTonenRechts.includes(e.Status_bemiddeling))
                .filter((e) =>
                    e.Status_inschrijving === 'Uitgeschreven' && state.passiefTonenRechts === false ? false : true
                );
            commit('SET_CURRENT_KLANT_RECHTS', -1);
            commit('UPDATE_GETOOND_RECHTS', getoond);
        },
        specifiekeKlantRechts({ commit, rootState }, klant) {
            const klanten = rootState.BemiddelingStore.klanten.filter((e) => e.id === klant.id);
            commit('SET_FILTER_RECHTS', klanten);
            commit('UPDATE_GETOOND_RECHTS', klanten);
            commit('SET_CURRENT_KLANT_RECHTS', 0);
        },
        zoekKlantRechts({ commit, rootState }, zoekwaarde) {
            const klanten = rootState.BemiddelingStore.klanten.filter((e) => {
                const zoekRegex = new RegExp(zoekwaarde, 'i');
                if (e.Account_Name.match(zoekRegex)) {
                    return true;
                }
                if (e.Primair_e_mailadres.match(zoekwaarde)) {
                    return true;
                }
                if (e.Relatornummer.match(zoekwaarde)) {
                    return true;
                }
            });
            commit('SET_FILTER_RECHTS', klanten);
            commit('UPDATE_GETOOND_RECHTS', klanten);
            commit('SET_CURRENT_KLANT_RECHTS', 0);
        },
    },
    getters: {
        currentFilterRechtsSettings(state) {
            return state.currentFilterRechtsSettings;
        },
        aantalKlantenInFilterRechts(state) {
            return state.getoondRechts.length;
        },
        filterRechts(state) {
            return state.getoondRechts;
        },
        currentKlantRechts(state) {
            return state.currentKlantRechts > -1 ? state.getoondRechts[state.currentKlantRechts] : {};
        },
        afstanden(state) {
            return state.afstanden;
        },
        voorstellenKlantRechts(state) {
            return state.voorstellenKlantRechts;
        },
        hotlist(state) {
            return state.getoondRechts.filter((e) => state.hotlist.includes(e.id));
        },
        statussenTonenRechts(state) {
            return state.statussenTonenRechts;
        },
        passiefTonenRechts(state) {
            return state.passiefTonenRechts;
        },
    },
};
